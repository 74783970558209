.picking_wrapper{
    // margin:30px;
    max-width: 500px;
     @media (max-width:770px) {
         margin:3px;
         max-width: 100%;
         button {
             width:100%;
             background-color: rgb(88, 124, 215);
         }
     }
    table {
        margin-bottom: 20px;
    }
    input {
        width:80px;
    }
}
