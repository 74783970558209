.table-cell-grid {
  display: grid;
  grid-template-columns: max-content auto;
  column-gap: 1em;
  font-size: 0.85em;
}

.table-cell-grid__label {
  text-align: right;
  color: grey;
}

// .table-cell-grid__grid {
// }

//  MODAL
.warehouse-modal {
  margin-bottom: 1em;
}
.warehouse-modal__grid {
  display: grid;
  grid-template-columns: 80px 220px;
  column-gap: 1.3em;
  row-gap: 0.6em;
  margin-bottom: 1.5em;
}
.warehouse-modal__label {
  text-align: right;
}
.warehouse-modal__label_light {
  font-weight: normal;
}
.warehouse-modal__address-label {
  display: block;
  width: 80px;
  font-weight: bold;
  text-align: right;
}
