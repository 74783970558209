.roles {
  ul {
    list-style-type: none;
    padding: 0;
  }
}

.rules__section {
  max-width: 100%;
  margin: 3.5em auto;
}
// .rules__list {
// }
.rules__list-item {
  margin-bottom: 1em;
}

.role-settings {
  background-color: rgba(255, 255, 255, 0.678);
  padding: 2em 1.5em;
  .ui.header {
    margin-bottom: 0.5em;
  }
}
.role-settings__list {
  margin-left: 1.5em;
  margin-bottom: 3.5em;
}
.role-settings__list_routes {
  margin-top: 1em;
}

// Filters
.filter-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}
.filter-item__name {
  width: 20%;
}
.filter-item__toggle {
  width: 15%;
}
.filter-item__all-check {
  width: 10%;
  display: flex;
  align-items: center;
  .ui.checkbox {
    margin: 0;
    margin-left: 0.7em;
    margin-top: 4px;
  }
}
.filter-item__options {
  width: 55%;
  position: relative;
}

// Views
.views-item__name {
  width: 20%;
}
.views-item__toggle {
  width: 25%;
}
.views-item__options {
  width: 55%;
}

// Routes
.route-item {
  border-bottom: 1px solid transparent;

  &:hover {
    background-color: #fff;
    border-bottom: 1px solid rgb(245, 245, 245);
  }
}
.route-item__name {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  width: 40%;
}
.route-item__options {
  width: 60%;
  display: flex;
}
.route-item__checkbox {
  width: 25%;
}

.role-settings__clickable-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 2.5em;
  background-color: rgba(218, 221, 224, 0.13);
  &:hover {
    background-color: rgba(218, 221, 224, 0.171);
  }
}

.role-settings-info-tag {
  position: absolute;
  z-index: 4;
  margin-top: 5px;
}
