// New Product
.add-products__wrapper {
  margin-bottom: 5em;
}
.product-form {
  margin-left: auto;
  margin-bottom: auto;
  width: 75%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 3.5em;
  margin-bottom: 5em;
  @media (max-width: 1050px) {
    width: 90%;
  }
  @media (max-width: 768px) {
    width: 75%;
  }
  @media (max-width: 500px) {
    width: 92%;
  }
}
.product-form__section {
  margin-bottom: 4em;
  .field .ui.input {
    margin-bottom: 0.7em;
  }
}
.feedback-section {
  text-align: center;
  margin: 2em 0;
  max-width: 350px;
}

// Product from Excel
.upload-products-header {
  margin-top: 1em;
}
.products-from-excel {
  margin-bottom: 6em;
}

.upload-file {
  margin-top: 4em;
  margin-bottom: 1.5em;
  text-align: center;
}
.upload-file__form {
  padding-top: 4em;
}
.upload-file__input {
  border-radius: 0.4em;
  font-size: 1.2em;
  fill: blue;
  border: 1px solid blue;
  color: blue;
  background-color: rgb(230, 230, 253);
  display: inline-block;
  padding: 0.8em 1.4em;
  cursor: pointer;
  &:hover {
    background-color: rgb(241, 241, 253);
    transition: background-color 0.3s;
  }
}
input[type="file"] {
  display: none;
}
.upload-file__error {
  color: #9f3a38;
}
.upload-file__drop-container {
  max-width: 550px;
  margin: 1em auto;
}

// Data Table
.view-table {
  margin-bottom: 6em;
}

// Feedback section
.feedback-section {
  text-align: center;
  margin: -2.5em auto 2.8em;
  max-width: 550px;
  border-radius: 0.3em;
  // box-shadow: 0 0 2px 1px rgba(175, 175, 175, 0.185);
  padding: 1em 1.4em;
  font-size: 1em;
  border: 1px solid #dbdddb;
}
.feedback-section_success {
  background-color: rgba(169, 241, 159, 0.068);
  border: 1px solid #c3f3c367;
}
.feedback-section_errors {
  background-color: rgba(247, 220, 68, 0.027);
  border: 1px solid #836c3317;
}

.feedback-section__success {
  color: #116925;
  line-height: 1.8;
}
.feedback-section__errors {
  color: #9c7c23;
  line-height: 1.8;
}

.save-products {
  text-align: center;
  margin-top: 2em;
  margin-bottom: 3em;
}
.feedback-section_server-response {
  margin-top: 1em;
}
