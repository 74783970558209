.create-transfer__form {
  width: 50%;
}

.create-transfer__summary {
  width: 50%;
  border: 1px solid rgb(191, 224, 247);
  border-radius: 8px;
  background-color: rgb(233, 246, 255);
  padding: 1em;
}
.create-transfer__summary-grid {
  display: grid;
  grid-template-columns: max-content auto;
  row-gap: 1.5em;
  column-gap: 1em;
  margin-left: 2em;
  margin-top: 2em;
  align-items: flex-start;
}

.create-transfer__section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 3em;
  max-width: 420px;
}
.create-transfer__number {
  display: inline-block;
  max-width: 24px;
  margin-right: 1em !important;
  justify-content: center;
  margin-bottom: 1em !important;
}

.create-transfer-bankaccount {
  display: inline-flex;
  flex-wrap: wrap;
}

.create-transfer__account-options {
  margin-bottom: 1em;
  width: 95%;
}
.bankaccount-field {
  max-width: 200px;
}

/// View/Edit
.view-transfer__field {
  display: flex;
  max-width: 400px;
  margin-bottom: 1em;
}
.view-transfer__label {
  display: block;
  width: 200px;

  padding-right: 0.7em;
  line-height: 2.3;
}
.view-transfer__amount {
  margin-top: 2.6em;
  margin-bottom: 2.7em;
  display: flex;
  max-width: 850px;
  flex-wrap: wrap;
  .view-transfer__field {
    display: flex;
    max-width: 200px;
    margin-bottom: 1em;
  }
  .view-transfer__label {
    width: 100px;
  }
}

.view-transfer__bankaccount {
  display: flex;
  margin-bottom: 2.6em;
  flex-wrap: wrap;
}
.view-transfer__bank-field {
  width: 140px;
  margin-right: 1em;
}

.view-transfer__notes {
  max-width: 600px;
  margin-bottom: 2em;
  margin-top: 2em;
}

// list
.transfers-bank-labels {
  display: inline-block;
  min-width: 70px;
}
