.App {
    text-align: center;
  }
  
  .App-header {
    background-color: #e9e9e9;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    /* font-size: calc(10px + 2vmin); */
    color: white;
    width: 800px;
  }
  
  input {
    padding: 10px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  /* button {
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #61dafb;
    color: white;
    cursor: pointer;
  } */
  
  /* button:hover {
    background-color: #21a1f1;
  } */
  