.top-bar {
  margin: 0em 0em 1em 0em;
}

// Order Info
.order-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  // width: 100%;
  margin-top: -1em;
  margin-bottom: -0.8em;
  @media (max-width: 1000px) {
    align-self: flex-start;
    width: 100%;
  }
}
.order-info__item {
  margin-right: 1em;
}
.order-info__label {
  line-height: 1.8;
  margin-right: 0.4em;
  color: grey;
}
.order-info__text {
  font-weight: bold;
}

.order-info__label_client {
  font-size: 1.3em;
}
.order-info__text_client {
  font-size: 1.5em;
}

.courier {
  width: 24%;
  vertical-align: center;
  @media (max-width: 1100px) {
    width: 35%;
  }
  @media (max-width: 700px) {
    width: 55%;
  }

  @media (max-width: 600px) {
    // align-self: flex-start;
    width: 100%;
    margin-top: 1em;
  }
}
// .courier__change {
// }
.courier__logo {
  display: block;
  height: 75px;
  width: auto;
  background-color: #999;
  margin-right: 2em;
  @media (max-width: 600px) {
    margin-right: 0;
  }
}
.courier__img {
  height: 100%;
  display: block;
}

.actions__primary {
  @media (max-width: 1000px) {
    margin-top: 1em;
  }
}
.primary-btn__wrapper {
  margin-bottom: 4em;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  // align-items: center;
}
.ui.pointing.label.actions__label {
  display: none;
  position: absolute;
  top: 80%;
}
.focused-btn {
  animation: pulse 1s ease-out infinite;
}
.focused-btn + .ui.pointing.label.actions__label {
  display: inline;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.03, 1.1);
  }
  100% {
    transform: scale(1);
  }
}

// Packing Info
.packing-info {
  margin: 0.7em auto 1.6em;
  @media (max-width: 900px) {
    flex-direction: column;
  }
  .ui.header {
    margin: 0.2em 0 -0.4em;
  }
}

.packing-info__order {
  width: 12%;
  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 1.8em;
  }

  .order-info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @media (max-width: 1000px) {
      align-self: flex-start;
      width: 100%;
    }
  }
  .order-info__item {
    margin-right: 0;
    margin-bottom: 1em;
    margin-top: 0.7em;
  }
  .order-info__label {
    line-height: 1.8;
    margin-right: 0;
    color: grey;
    display: block;
  }
  .order-info__text {
    font-weight: bold;
  }

  .order-info__label_client {
    font-size: 1.3em;
  }
  .order-info__text_client {
    font-size: 1.5em;
  }

  .courier__logo {
    margin-top: 0;
    display: block;
    height: auto;
    width: 100%;
    background-color: #999;
    margin-right: 0;
  }
  .courier__img {
    width: 100%;
    display: block;
  }
  .courier {
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.vertical-divider {
  display: block;
  width: 2px;
  background-color: #e2eef1;
  height: 340px;
  margin-top: 1em;
  //   align-self: center;
  @media (max-width: 900px) {
    display: none;
  }
}
.packing-info__products {
  width: 62%;
  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 1.8em;
  }
  .ui.segment {
    background-color: rgb(249, 255, 166);
  }
}
.packing-info__sidebar {
  width: 36%;
  @media (max-width: 900px) {
    width: 100%;
  }
}

.sidebar-container {
  padding: 0 1em 0.5em;

  @media (max-width: 900px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 0.5em;
  }
  @media (max-width: 650px) {
    display: block;
  }
}

.packing-info__print {
  width: 100%;
  margin-bottom: 3.5em;
  @media (max-width: 900px) {
    width: 49%;
    margin-bottom: 0;
  }
  @media (max-width: 650px) {
    width: 100%;
    margin-bottom: 3.5em;
  }
}
.packing-info__pack-materials {
  width: 100%;
  @media (max-width: 900px) {
    width: 49%;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
}

// Products Info
.products-section__wrapper {
  width: 96%;
  margin: 0 auto 0;
}
.products-list {
  margin: 0;
  padding: 0;
}
.products-list__item {
  margin: 0;
  padding: 0;
  padding-bottom: 0.5em;
  border-bottom: 2px solid rgb(202, 202, 202);
  margin-bottom: 1em;
  &:last-of-type {
    border-bottom: none;
  }
}
.products-list__row {
  margin: 0;
  padding: 0;
}
.product-list__label {
  margin-right: 0.5em;
  font-size: 0.9em;
  color: grey;
  min-width: 25%;
  background-color: rgb(235, 235, 235);
  display: inline-block;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 0.6em;
}
.product-list__text {
  font-weight: bold;
}

.ui.table.product-table {
  border-radius: none;
  margin-top: -14px;
  td {
    padding: 0.35em 0.35em 0.35em 0;
  }
}

.ui.basic.table.product-table tbody tr {
  border-bottom: 2px solid rgb(202, 202, 202) !important;
}

.ui[class*="very basic"].table:not(.sortable):not(.striped)
  thead
  tr:first-child
  th.products-table__header {
  color: rgb(107, 107, 107);
  font-size: 1.1em;
  background-color: rgb(235, 235, 235);
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  padding-left: 0.6em;
}
.product-table__cell {
  font-size: 1.8em;
}
.product-table__image {
  width: 100px;
  height: 100px;
  background-color: rgb(228, 228, 228);
}

// Pack Materials
.pack-materials {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1em;
}
.pack-materials__label {
  display: inline-block;
  min-width: 30%;
  font-weight: bold;
}
// .pack-materials__text {
//
// }

// Print Section
.ui.header:first-child.print__header {
  margin-top: 0;
  margin-bottom: 0;
}
// .print-list {
//   margin: 0;
//   padding: 0;
//   list-style-type: none;
// }
// .print-list__item {
//   margin: 0;
//   padding: 0.1em 0;
// }
// .print-list__label {
//   margin: 0;
//   padding: 0;
//   display: inline-block;
//   min-width: 45%;
//   font-weight: bold;
// }

.print-table__label {
  font-weight: bold;
}
.print-table__button {
  text-align: right;
  padding-bottom: 0.5em;
}
.alimated-ellipsis {
  display: inline-block;
  min-height: 4px;
  // border: 1px solid red;
  animation: animated-ellipsis 3s ease infinite;
  overflow: hidden;
  position: absolute;
  top: 2px;
  left: 44px;
}
@keyframes animated-ellipsis {
  0% {
    width: 3px;
  }
  25% {
    width: 6px;
  }
  50% {
    width: 9px;
  }
  75% {
    width: 0px;
  }
  100% {
    width: 3px;
  }
}

// Actions Btns
.actions {
  margin-top: 1em;
  margin-bottom: 5.6em;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
}
.actions__secondary-btns {
  display: flex;
  justify-content: flex-start;
}

.reject-btn__wrapper {
  // display: inline-block;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 0 1px #db2828 inset !important;
  border-radius: 0.3em;
  padding: 6px;
  transition: padding 0.3s;
  box-sizing: border-box;
}
.reject-btn__wrapper_closed {
  padding: 0px;
  transition: padding 0.3s;
}

.reject-btn__wrapper .ui.basic.red.button {
  box-shadow: 0 0 0 1px transparent !important;
}
.reject-reason__input {
  width: 100% !important;
  display: block;
}
.reject-reason__input .ui.input > input {
  display: inline;
}

// Draft List
.draft-list {
  margin-bottom: 5em;
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.barcode {
  width: 100%;
  display: block;
}


.barcode__logo-container {
  margin: 3cm 1cm 1cm;
  object-fit: contain;
  border: 1px solid red;
  text-align: center;
}

