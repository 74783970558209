body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
a:hover {
  text-decoration: none !important;
}

.ui.checkbox {
  margin-bottom: 0;
}

// Wrappers
.page-wrapper {
  padding: 20px;
  margin-right:30px;
  padding-bottom: 60px;
  max-width: 100vw;
  @media (max-width: 500px) {
    padding-left: 10px;
    padding-right: 10px;
    margin:2px;
  }

  h1.ui.header {
    font-size: 1.5em;
    display: block;
    margin: 0;
  }

  h2.ui.header {
    font-size: 1.2em;
    display: block;
    margin: 0;
  }
}

.a-wrapper {
  // max-width: 1150px;
  width: 94%;
  margin-left: auto;
  margin-right: auto;
  // @media (max-width: 1100px) {
  //   width: 97%;
  // }
  @media (max-width: 760px) {
    width: 92%;
  }
  @media (max-width: 600px) {
    width: 97%;
  }
}

.table-wrapper--horizontal-scroll {
  overflow-x: none;
  @media (max-width: 1000px) {
    overflow-x: scroll;
  }
}

// Flex classes
.flex--between {
  display: flex;
  justify-content: space-between;
  flex-wrap:wrap;
}
.flex--left {
  display: flex;
  justify-content: flex-start;
  flex-wrap:wrap;

}
.flex--center {
  display: flex;
  justify-content: center;
  flex-wrap:wrap;

}
.flex--right {
  display: flex;
  justify-content: flex-end;
  flex-wrap:wrap;

}
.vertical--top {
  align-items: flex-start;
}
.vertical--center {
  align-items: center;
}
.vertical--bottom {
  align-items: flex-end;
}
.flex--column {
  display: flex;
  flex-direction: column;
}

// Text Classes
.uppercase {
  text-transform: uppercase;
}
.bold--text {
  font-weight: bold;
}
.normal--text {
  font-weight: normal;
}
.grey--text {
  color: gray;
}
.dark-grey--text {
  color: rgb(53, 52, 52);
}
.small--text {
  font-size: 0.85em;
}
.large--text {
  font-size: 1.2em;
}
.x-large--text {
  font-size: 1.5em;
}
.right--text {
  text-align: right;
}
.center--text {
  text-align: center;
}
.left--text {
  text-align: left;
}

//Margin Classes
.mt--1 {
  margin-top: 1em;
}
.mr--1 {
  margin-right: 1em;
}
.mb--1 {
  margin-bottom: 1em;
}
.ml--1 {
  margin-left: 1em;
}

.mt--2 {
  margin-top: 2em;
}
.mr--2 {
  margin-right: 2em;
}
.mb--2 {
  margin-bottom: 2em;
}
.ml--2 {
  margin-left: 2em;
}

// Pagination
@media (max-width: 450px) {
  .page-wrapper .ui.pagination.menu .item {
    min-width: 0em;
    max-width: 2.5em;
    text-align: center;
    padding: 0.6em;
  }
}
