// ADD Product Component
// ADD Product Component
.add-product-form {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .add-product-button {
    max-height: 36px;
  }
  @media (max-width: 650px) {
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    .add-product-button {
      margin-top: 1em;
      width: 100%;
    }
  }
}
.add-product-form__fields {
  margin: 0 !important;

  .add-product-form__field {
    width: 200px;
    margin: 0 1em 0 0;
  }
  @media (max-width: 650px) {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    .add-product-form__field {
      width: 100%;
      margin: 0 0 0.5em 0;
    }
  }
}

// Product Table
.product-list-quantity-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 5px;
  column-gap: 10px;
  text-align: center;
  @media (max-width: 600px) {
    text-align: left;
  }
}
.product-list-quantity-grid--label {
  align-self: center;
  text-align: right;
  @media (max-width: 600px) {
    text-align: left;
  }
}
