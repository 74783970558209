.span-text_tabs {
  font-size: 0.8em;
  margin-left: 0.4em;
  font-weight: bold;
}
p.span-text_bigger {
  font-size: 0.9em;
  max-width: 80%;
}

// Received Orders List

.order-list {
  margin: 10px 10px 40px 20px;
}

// .filters_wrapper {
//   margin: 0;
//   display: flex;
//   flex-direction: column;
// }

.orders-table-actions {
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr;
  // row-gap: 0.5em;
  display:flex;
  justify-content: space-between;
  flex-wrap: wrap;

  // @media (max-width: 1000px) {
  //   grid-template-columns: 1fr 1fr 0.4fr 1fr;
  // }
  // @media (max-width: 900px) {
  //   grid-template-columns: 1fr 1fr 0.8fr;
  // }
  // @media (max-width: 800px) {
  //   grid-template-columns: 1fr 1fr;
  // }
  // @media (max-width: 600px) {
  //   grid-template-columns: 40px auto;
  // }
}

.orders-table-actions__actions-menu {
  @media (max-width: 600px) {
    grid-column: 1 / 3;
  }
}

.orders-table-actions__search {
  @media (max-width: 800px) {
    justify-self: end;
  }
  @media (max-width: 600px) {
    grid-column: 1 / 3;
    justify-self: start;
  }
}

.orders-table-actions__filters-toggle {
  justify-self: start;
}

.orders-table-actions__export-btn {
  justify-self: end;

  @media (max-width: 900px) {
    grid-column: 1 / 4;
  }
  @media (max-width: 800px) {
    grid-column: 2 / 3;
  }
}
// .filter_status_orders {
//   width: 220px;
//   margin-right: 5px;
//   @media (max-width: 700px) {
//     width: 100%;
//     top: 0;
//     left: 0;
//   }
// }

.filters__row {
  display: none;
}
.filters__row_visible {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap:wrap;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}
.filters__part {
  margin-right: 1em;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
}
.filters__part_last {
  margin-right: 0;
}
.filters__input {
  margin-right: 0.5em;
  margin-bottom: 4px;
  display: inline-block;
  @media (max-width: 1000px) {
    margin-bottom: 0.5em;
  }
}

.status-nav {
  padding-left: 0;
  margin-bottom: 0em;
  display: flex;
  justify-content: flex-start;
  flex-wrap:wrap;
  box-sizing: border-box;
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }

  // border-bottom: 1px solid rgb(180, 180, 180);
  &__item {
    list-style-type: none;
    // margin-right: 1em;
    padding-top: 1em;
    padding-left: 1em;
    padding-right: 1.5em;
    padding-bottom: 0.6em;
    color: grey;
    border-bottom: 1px solid rgb(209, 209, 209);
    white-space: nowrap;
    &:last-of-type {
      border-radius: 0em 0.3em 0 0;
    }
    &:hover {
      color: cornflowerblue;
      border-bottom: 1px solid cornflowerblue;
      cursor: pointer;
      .span-text_tabs {
        color: cornflowerblue;
      }
    }
    &_active {
      color: rgb(12, 12, 12);
      border-bottom: 1px solid rgb(105, 105, 105);
      .span-text_tabs {
        color: rgb(12, 12, 12);
      }
    }
  }
}
//Orders List Actions Menu
.export-btn {
  position: relative;
  cursor: pointer;
  display: block;
  border: none;
  height: auto;
  margin: 0;
  text-align: left;
  font-size: 1em !important;
  padding: 0.78571429em 1.14285714em !important;
  background: 0 0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  text-transform: none !important;
  font-weight: 400 !important;
  box-shadow: none !important;
  -webkit-transition: none !important;
  transition: none !important;
  i {
    position: absolute;
    display: inline-block;
    float: none;
    margin: 0 0.75em 0 0 !important;
  }
  span {
    position: relative;
    left: 1.9em;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.05) !important;
  }
}

// *

.products {
  margin-top: 40px;
}

// Orders Table
.span-text {
  color: grey;
  font-size: 0.7em;
  margin-right: 0.7em;
  font-weight: bold;
}

// Create New Order Component
.create-order__wrapper {
  padding-top: 0.7em;
  padding-bottom: 10em;
}
.form-wrapper {
  width: 89%;
  // max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 4em;
  @media (max-width: 900px) {
    width: 90%;
  }
  @media (max-width: 768px) {
    width: 75%;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
}
.create-order .form-wrapper {
  margin-top: 2.5em;
  margin-bottom: 2em;
}
.create-form__sub-header {
  padding-top: 3.5em;
  padding-bottom: 1em;
}
.client-label {
  color: #000;
  font-size: 0.925em;
  margin-bottom: 4px;
}
.form__client-field {
  margin-left: 7px;
  margin-right: 7px;
}

.form-columns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2em;
  @media (max-width: 900px) {
    flex-wrap: wrap;
    align-items: center;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
}
.form-columns__client {
  width: 30%;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 3em;
  }
}
.form-columns__adress {
  width: 65%;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 2em;
  }
}
.form-columns__bank {
  width: 95%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 3em;
}

.ui.header.form__header {
  margin-bottom: 0 !important;
}

div.ui.form__sub-header {
  margin: 3em 0 -0.5em;
}
div.ui.divider.form__divider {
  margin: 6em 0 0;
}

.vertical-align {
  align-items: center;
}
.margin-bottom {
  margin-bottom: 2em !important;
}

.uppercase {
  text-transform: uppercase;
}

.flex-x-end {
  display: block;
  width: 100%;
  justify-content: space-between;
  text-align: right;
}

// Products Componet
.products {
  margin-top: 8em;
  margin-bottom: 3rem;
}

.padding-top {
  padding-top: 2em !important;
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

// Edit/View Order Component
// .edit-view-link {
//   margin-left: 1em;
// }
// .edit-view-link:last-of-type {
//   margin-right: 3em;
// }
// .edit-view-link:first-of-type::after {
//   content: "|";
//   margin-left: 1em;
//   color: #dc5a60;
//   font-weight: normal;
// }

// .edit-view-link__active {
//   font-weight: bold;
// }

.view-form__wrapper {
  // width: 95%;
  // max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  // margin-top: 3.6em;
  margin-bottom: 0;
  @media (max-width: 900px) {
    width: 95%;
  }
  @media (max-width: 768px) {
    width: 85%;
  }
  @media (max-width: 600px) {
    width: 97%;
    margin-top: 1.5em;
    margin-bottom: 0;
  }
}
.view-form__columns {
  display: flex;
  justify-content: space-between;
  align-items: flex-center;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 3.5em;
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.view-form__wrapper_options {
  margin-top: 2.5em;
  margin-bottom: 2.5em;
}

.ui.grid {
  margin-top: 0rem;
  margin-bottom: 0rem;
  margin-left: 0rem;
  margin-right: -1rem;
}
.ui.grid > .row {
  width: 100% !important;
  padding: 0;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

// Editable Field
.editable-field__text {
  cursor: pointer;
  height: 32px;
  line-height: 31px;
  border-bottom: #f0f0f0 1px solid;
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  background-color: #ffffff;
  display: block;
  position: relative;
  // width: 100%;
  border-radius: 0;
  // padding-left: 1em;
  box-sizing: border-box;
  // min-width: 10em;
  &:hover {
    border-bottom: #dbdbdb 1px solid;
    background-color: #f7f7f7;
  }
}

.editable-field_non-editable {
  height: 32px;
  line-height: 31px;
  border-bottom: #f0f0f0 1px solid;
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  background-color: #ffffff;
  display: block;
  position: relative;
  width: 100%;
  border-radius: 0;
  padding-left: 1em;
  box-sizing: border-box;
  min-width: 10em;
}

// .ui.fluid.input > input.editable-field__input {
//   height: 32px;
// }

.editable-field__icons {
  position: absolute;
  height: 28px;
  right: 18px;
  opacity: 1;
  background-color: #f7f7f7;
}
.editable-field__icons_invisible {
  opacity: 0;
}

// Print
@media print and (width: 21cm) and (height: 29.7cm) {
  @page {
    margin: 3cm;
  }
}

/* style sheet for "letter" printing */
@media print and (width: 8.5in) and (height: 11in) {
  @page {
    margin: 1in;
  }
}

/* A4 Landscape*/
@page {
  size: A6 landscape;
  margin: 5%;
}

// Change Staus Modal
.change-status-form {
  max-width: 300px;
  // text-align: center;
}

.change-status-form__section {
  margin-bottom: 1.7em;
}

// Toggle Button
.filters__toggle {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.85em;
  color: rgb(59, 59, 59);
  line-height: 0.85em;
}

.toggle-button {
  margin-top: 0.5em;
  height: 20px;
  width: 45px;
  border-radius: 10px;
  border: none;
  outline: none !important;
  transition: background-color 0.3s;
  cursor: pointer;
}
.toggle-button_off {
  background-color: rgb(207, 207, 207);
}
.toggle-button_on {
  background-color: rgb(114, 114, 114);
}
.toggle-button:after {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: rgb(246, 251, 255);
  box-shadow: 1px 1px 3px 1px rgba(124, 113, 113, 0.192);
  position: relative;
  left: -4px;
  transition: transform 0.3s;
}
.toggle-button_off:after {
  transform: translateX(0);
}
.toggle-button_on:after {
  transform: translateX(25px);
  box-shadow: 1px 1px 3px 1px rgba(17, 17, 17, 0.534);
}

///
//order timeline
.rs-timeline-item-last .rs-timeline-item-dot::before {
  background-color: #d9d9d9;
}
.rs-timeline-item-first .rs-timeline-item-dot::before {
  background-color: #3498ff;
}

.order_right {
  margin:100px 5px 0 10px;
  // min-width: 300px;
  width:92%;

  @media (max-width: 600px) {
      
    }
}