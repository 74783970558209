.inbound {
  padding: 20px;
  display: flex;
  flex-direction: column;

  .filter_status {
    width: 200px;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    @media (max-width: 700px) {
      width: 100%;
      top: 0;
      left: 0;
      margin-bottom: 0.5em;
    }
  }
  .filters_wrapper {
    display: flex;
    flex-direction: column;
  }
  &_wrapper {
    #inbound_id h3 {
      margin: 0;
    }
    // width:100%;
    // width:70em;
    margin-left: 30px;
    margin-right: 30px;
    background-color: #f3f3f3 !important;

    @media (max-width: 700px) {
      width: 100%;
      top: 0;
      left: 0;
      margin: 24px 0px 0px 0px;
      position: relative;
    }
  }

  &_body {
    padding: 12px;
  }

  &_header {
    padding: 12px;
  }
  &_info {
    display: grid;
    // grid-gap: 5px;
    /* Space between items */
    grid-template-columns: 1fr 1fr 1fr;
    @media (max-width: 700px) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      top: 0;
      left: 0;
    }
    @media (max-width: 380px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      top: 0;
      left: 0;
    }

    span {
      margin-left: 10px;
    }
  }
  &_photos {
    margin-top: 10px;
  }
  &_actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  &_goodsreceived {
    .items {
      #image {
        height: 120px;
        width: 120px;
        padding: 5px;
      }
      table img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    background-color: white;
    margin-top: 20px;
  }
}

.item-main {
  #quantity {
    width: 50%;
  }
}
.images {
  .image {
    display: flex;
    height: 120px;
    // padding: 10px;
    img {
      max-height: 100%;
    }
  }
}
table {
  width: 100%;
  // border-spacing: 50px 0;
}

///////////////////////////////////////////////
// Create New Inbound Shipment
.new-inbound-shipment__grid {
  margin-top: 1em;
  max-width: 700px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1em;
  column-gap: 3em;
  @media (max-width: 650px) {
    column-gap: 1.5em;
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
}
.new-inbound-shipment__col {
  display: flex;
  flex-direction: column;
}
.new-inbound-shipment__field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

//////////////////////////////////////
// Edit Inbound Shipment
.ui.header.edit-inbound-shipment__header {
  display: block;
  margin: 0;
}
.edit-inbound-shipment__grid {
  margin-top: 2.5em;
  margin-bottom: 1em;
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 3em;
  row-gap: 2em;
  align-items: flex-start;

  @media (max-width: 900px) {
    column-gap: 1em;
  }
  @media (max-width: 790px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 2em;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
.edit-inbound-shipment__col {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0.6em;
}
.edit-inbound-shipment__field {
  display: grid;
  grid-template-columns: 90px auto;
  row-gap: 1em;
  column-gap: 1em;
  align-items: center;
  label {
    margin-bottom: 0;
  }

  @media (max-width: 900px) {
    row-gap: 0;
  }
  @media (max-width: 500px) {
    row-gap: 0.5em;
  }
}

// items
.inbound-goodsreceived__label {
  display: inline-block;
  min-width: 50px;
  margin-right: 10px;
}
