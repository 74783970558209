// Vertical display
// .courier-report-template__grid {
//   margin-top: 2em;
//   margin-bottom: 1em;
//   max-width: 330px;
//   display: grid;
// }
// .courier-report-template__field {
//   display: grid;
//   grid-template-columns: 110px auto;
//   column-gap: 10px;
//   align-items: center;
//   margin-bottom: 10px;
// }

// Horizontal display
.courier-report-template {
  padding: 10px;
}
.courier-report-template__grid {
  margin-top: 2em;
  margin-bottom: 2em;
  max-width: 1100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1em;
  row-gap: 1em;
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1em;
  }
  @media (max-width: 400px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.courier-report-template__field {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2px;
}

// Couriers List
// Modal
.courier-form__field {
  margin-bottom: 1em;
  min-width: 300px;
  display: grid;
  grid-template-columns: 1fr;
}

// Courier Users
// Modal
.courier-users__grid {
  display: grid;
  grid-template-columns: 100px auto;
  row-gap: 1em;
  margin-bottom: 2em;
}
.add-remove-items-section {
  width: 550px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
  max-height: 320px;
}
.view-items-table {
  width: 250px;
  margin-right: 50px;
}
.add-items-table {
  margin: 0;
  width: 250px;
}
.add-items-table__body {
  width: 250px !important;
  display: block;
  max-height: 250px;
  overflow-y: auto;
}
.view-items-table__body {
  display: block;
  max-height: 320px;
  overflow-y: auto;
}
