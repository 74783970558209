.d-flex--jc-between {
  display: flex;
  justify-content: space-between;
}
.ai-center {
  align-items: center;
}
.d-flex--column {
  display: inline-flex;
  flex-direction: column;
}

// Top Bar
.top-filters__container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-right: 4em;
  @media (max-width: 600px) {
    flex-direction: column;
    margin-right: 3em;
    width: 100%;
  }
  @media (max-width: 460px) {
    margin-right: 1em;
  }
}
.top-filters__box {
  margin-right: 4em;
  @media (max-width: 760px) {
    margin-right: 1em;
  }
}
.top-filters__column {
  display: inline-flex;
  flex-direction: row;
  @media (max-width: 600px) {
    flex-direction: row;
  }
}

// PickerListByProduct
.courier-cell {
  overflow: visible !important;
}
.courier-cell:hover {
  background-color: rgb(245, 245, 245) !important;
}
.courier-cell:hover .caret-down {
  display: inline !important;
  font-size: 1em;
}
.caret-down {
  cursor: pointer;
  display: none !important;
}

// PickerListByCourier
.text-center {
  text-align: center;
}

// PickedListPage > FiltersBar
.top-filters__date-picker {
  margin-right: 2.5em;
  @media (max-width: 600px) {
    margin-right: 0;
    margin-bottom: 0.5em;
    width: 100%;
  }
}
.date-pick__item {
  margin-right: 0.3em;
  display: inline;
  @media (max-width: 600px) {
    display: block;
    margin-right: 0;
    margin-bottom: 0.5em;
  }
}

// Modal
.modal-wrapper {
  margin: 2.5em 2em 0.7em;
  @media (max-width: 600px) {
    margin: 2em 0.2em 0.5em;
  }
}

.fixedHTable {
  border-collapse: collapse;
  width: 100%;
  display: block;
  max-height: 55vh;
  overflow: auto;
}

.fixedHTable th {
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 3;
}
