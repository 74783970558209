.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  padding-top: 10px;
  padding-bottom: 0;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 12px;
  line-height: 1.3;
  font-family: "Helvetica Neue", "Helvetica";
  color: rgb(26, 26, 26);
  background-color: white;
  table {
    width: auto;
  }
}

.text_large {
  font-size: 15px;
}

.text_medium {
  font-size: 13px;
}

.text_bold {
  font-weight: bold;
}

.black-outline {
  border: 2px solid black;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h1 {
  font-size: 20px;
  margin-bottom: 5px;
}

header span {
  font-size: 16px;
  font-weight: bold;
}

.main-table {
  display: flex;
  width: 100%;
  border-top: 5px solid #000;
  border-left: 0.5px solid #000;
  border-bottom: 0.5px solid #000;
  border-right: 0.5px solid #000;
}

.main-table__col {
  width: 50%;
  display: inline-block;
  border-right: 0.5px solid #000;
}

.main-table__cell {
  border-bottom: 0.5px solid #000;
  padding: 7px 10px;
}

.main-table__cell_last {
  border-bottom: none;
}

/* Col 1 */
/* Suplier */
.supplier-info {
  margin-top: 0px;
  margin-left: 95px;
  margin-bottom: 8px;
  line-height: 1.5;
}
.supplier__name {
  margin-bottom: 15px;
}
// .supplier__logo {
//   margin: auto;
//   width: 70%;
// }
// .supplier__logo img {
//   max-width: 90%;
// }

/* Bank */
.bank-table {
  min-width: 90%;
}
/* Payment */
.payment-table {
  min-width: 65%;
}

/* COL 2 */
.col2__table {
  display: flex;
}
.col2__label {
  width: 30%;
}

/* Customer Address */
.customer-address {
  margin-bottom: 14px;
  display: block;
}
/* Shipping */
.main-table__col:nth-of-type(2) .main-table__cell_shipping {
  border: outset black;
}
.shipping-address {
  display: block;
  margin-top: 20px;
  margin-bottom: 26px;
  line-height: 1.3;
}
/* Destination */
.destination {
  margin-bottom: 10px;
}
/* Payment Dates */
.payment-dates__grid {
  display: grid;
  grid-template-columns: auto max-content;
  gap: 0px;
}
.payment-dates__label {
  text-align: right;
  padding-right: 10px;
  vertical-align: middle;
}
.payment-dates__date {
  padding: 0px 3px;
}

// Products Table
.items {
  margin-top: 28px;
  margin-bottom: 40px;
}
.products-table {
  min-width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  .item-name {
    text-align: left;
  }
  th {
    padding-bottom: 10px;
    border-bottom: 2px solid black;
    border-top: none;
    border-left: none;
    border-right: none;
    text-align: right;
  }
  td {
    padding-top: 3px;
    padding-bottom: 4px;
    border-bottom: 0.5px solid rgb(105, 105, 105);
    border-top: none;
    border-left: none;
    border-right: none;
    text-align: right;
  }
}
// .notes {
//   padding-left: 10px;
//   margin-bottom: 0px;
// }

// Recapitulation
.recap-tables {
  display: flex;
  justify-content: flex-end;
}

.recap-tables__second {
  min-width: 38%;
  border: 2px solid black;
  td {
    border: 1px solid black;
    padding: 5px;
  }
}

// Signature
.signature {
  width: 35%;
  margin-left: 65%;
  border-bottom: 2px dashed rgb(78, 78, 78);
  min-height: 85px;
  margin-bottom: 200px;
}
