.inventory {
  .filters_ {
    flex-direction: column;
    display: flex;

    .filter_status {
      width: 220px;
      margin-right: 5px;
    }

    p:hover {
      cursor: pointer;
    }

    p {
      margin-left: 10px;
      text-decoration: underline;
    }
  }
  // max-width: 900px;
  margin: 10px 10px 0px 20px;
  .table_header {
    padding: 12px;
  }
}

.inventoryModal {
  width: 35em;
}

//Mobile Item Card
.warehouse-card {
  text-align: center;
}
.warehouse-card--head {
  display: flex;
  justify-content: center;
  align-items: middle;
  margin-top: 0.65em;
}
.warehouse-card--name {
  margin-bottom: 0.2em;
  padding-left: 1.2em;
}
.warehouse-card--grid {
  padding: 1em;
  padding-top: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
  row-gap: 10px;
  div {
    color: #3d5170;
  }
}

/// Inventory Counts
.inventory-counts-quantity {
  display: grid;
  grid-template-columns: 80px auto;
}

// Create , modal
.inventory-count-position-and-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}
.inventory-count-date {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 1em;
  transform: translateY(-2px);
}
