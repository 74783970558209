$light-blue: #e6f8f4;

.product-list-container {
  margin-bottom: 4em;
  .segment.ui {
    margin-top: -2px;
  }
  // .ui.menu {
  //   margin-bottom: 0;
  // }
}
.prod-list-loader {
  margin-top: 2em;
}

.product-row__img-container {
  width: 120px;
  height: 120px;
  background-color: #ede4ff;
  img {
    width: 120px;
    height: 120px;
  }
}
.product-row__row {
  margin-bottom: 0.5em;
  font-size: 0.9em;
}
.product-row__row_name {
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 1.2em;
  margin-top: 0.2em !important;
}
.product-row__label {
  // font-weight: bold;
  display: inline-block;
  min-width: 40px;
  color: gray;
}
.index-col-padding {
  padding-left: 1em !important;
  padding-right: 0.5em !important;
}
.pagination-centered {
  margin: 2em auto 0em;
  text-align: center;
}
//////

.product {
  //   background: #92b0b3;
  // max-width: 900px;
  .product_info {
    margin-top: 1.5em;
    display: grid;
    grid-template-columns: 1fr 3.5fr;

    @media (max-width: 600px) {
      padding-top: 1em;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      margin: 0;
    }
  }

  table {
    border-collapse: collapse;
    margin: 12px 0 0 0;
    width: 100%;
  }
  th {
    padding: 0.6em 1em;
    background: #e2e6e569;
    font-weight: bold;
  }
  td {
    padding: 0.3em 1em;
    //   align: left;
    //   valign: top;
  }

  .hoverTable {
    border-collapse: collapse;
    margin: 12px 0 0 0;
    width: 100%;
    display: block;
    height: 350px;
    overflow: auto;
  }

  .hoverTable th {
    padding: 0.6em 1em;
    background: #d0f0e9;
    font-weight: bold;
    border: #edfdf9 1px solid;
    position: sticky;
    top: 0;
  }
  .hoverTable td {
    padding-bottom: 12px;
  }

  /* Define the default color for all the table rows */
  // .hoverTable tr {
  // }

  /* Define the hover highlight color for the table row */
  .hoverTable tr:hover {
    background-color: #eff6f5;
  }

  .stock-available-table {
    margin-top: 1em;
    th {
      padding: 1.2em 1em;
      background: $light-blue;
      font-weight: bold;
      border: #edfdf9 1px solid;
      // position: sticky;
      top: 0;
    }
    td {
      padding-bottom: 12px;
      padding-top: 12px;
    }
  }

  .card_ {
    width: 100%;
    margin-top: 10px;
    // min-width: 1100px;
    // background-color: gray;
    grid-area: productfiles;
    margin-bottom: 2px;
    padding: 10px 0;

    //   max-height: 300px;
    //   overflow-y: auto;
    @media (max-width: 1000px) {
      height: 100%;
      width: 100%;
      margin: 0;
    }
  }
}

// #cards{
//     margin-left:30px;
//     max-width: 300px;
//     @media (max-width:700px) {
//         max-width: 100%;
//         margin:0;
//     }
//     }

.product_wrapper {
  background-color: white;
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-template-columns: 250px auto;
  grid-template-areas:
    "image productname "
    "image productoptions "
    "productfiz productfiz "
    "productpacking productpacking ";
}
#product_img {
  padding: 12px;
  grid-area: image;
  margin-bottom: 2px;
}

#product_name {
  background-color: gainsboro;
  grid-area: productname;
  padding: 12px;
  margin-bottom: 2px;
}

#product_options {
  grid-area: productoptions;
  background-color: orange;
  margin-bottom: 2px;
  padding: 12px;
}

#product_packing {
  background-color: rgb(85, 112, 114);
  grid-area: productpacking;
  margin-bottom: 2px;
  padding: 12px;
  height: 120px;
}

#product_web {
  background-color: rgb(206, 148, 148);
  grid-area: productweb;
  margin-bottom: 2px;
  padding: 12px;
  max-height: 300px;
  overflow-y: auto;
}

#product_data {
  background-color: yellow;
  grid-area: productfiz;
  margin-bottom: 2px;
  padding: 12px;
}

// #product_label {
//   grid-area: productlabel;
//   margin-bottom: 2px;
//   max-height: 400px;
//   overflow-y: auto;
// }

.files input {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  padding: 120px 0px 85px 35%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
}

.files input:focus {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  border: 1px solid #92b0b3;
}

.files {
  position: relative;
}

.files:after {
  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: "";
  // background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}

.color input {
  background-color: #f1f1f1;
}

.files:before {
  position: absolute;
  bottom: 10px;
  left: 0;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  // content: " or drag it here. ";
  display: block;
  margin: 0 auto;
  color: #2ea591;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}

// Edit/View Product Form
.product-info-grid {
  margin-bottom: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2.5em;
  @media (max-width: 600px) {
    margin-top: 1.7em;
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
}
.product-info-grid--part {
  display: grid;
  grid-template-columns: 100px auto;
  column-gap: 1em;
  @media (max-width: 600px) {
    grid-template-columns: 120px auto;
  }
}
.product-info-grid--label {
  background-color: $light-blue;
  padding: 0.5em 1em;
}

//Pricing
.product-pricing__table {
  margin-top: 1em;
  th {
    padding: 1.2em 1em;
    background: $light-blue;
    font-weight: bold;
    border: #edfdf9 1px solid;
    // position: sticky;
    top: 0;
  }
  td {
    padding-bottom: 12px;
    padding-top: 12px;
  }
}

.product-pricing-form__grid {
  margin-bottom: 1em;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  row-gap: 1em;
  column-gap: 0.5em;
}
.product-pricing-form__grid {
  column-gap: 0.2em;
}
// .product-pricing-form__field {
// }
// .product-pricing-form__label {
// }

// Copy Pricing
.copy-pricing__grid {
  padding: 1em;
  min-height: 380px;
  display: grid;
  grid-template-columns: auto 427px;
  column-gap: 3em;

  ol {
    padding-left: 1em;
  }

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
    min-height: auto;
    padding: 0;
  }
}
