.pocomponent-wrapper {
  margin-top: 1em;
  margin-bottom: 3em;
}

.pocomponent {
  display: grid;
  grid-template-areas: "po comments";
  grid-template-columns: 70% auto;
  grid-gap: 1.4em;
  margin-bottom: 20px;
  margin-top: 1em;

  // main .ui.segment {
  //   padding: 1.4em 1.8em;
  //   @media (max-width: 800px) {
  //     padding: 1.2em 1.4em;
  //   }
  // }
  sidebar {
    .ui.segment {
      padding: 1.2em 1.4em;
      background-color: rgba(255, 255, 255, 0.822) !important;
    }
  }

  @media (max-width: 850px) {
    grid-template-areas:
      "po "
      "comments";
    grid-template-columns: 100%;
  }
}

.po_client_form {
  /* width:60%; */
  padding-top: 2em;
  padding-bottom: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: blur(0);
}

.newpo__products {
  margin-top: 1.5em;
  margin-bottom: 2em;
}

.po-form__grid-cell-content {
  margin: 2.6em 1.2em 0.5em;
}
.po-form__grid-cell-content_parties {
  margin-top: 0.6em;
}
.parties__row {
  margin-top: 0.7em;
  display: flex;
}
.parties__addbtn {
  transform: scale(0.95);
}

.po-details__label {
  font-weight: bold;
  min-width: 5.7em;
  display: inline-block;
}
