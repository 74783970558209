// Table
.clickable-cell-content {
  cursor: pointer;
  color: rgb(94, 94, 94);
  &:hover {
    color: black;
  }
}

// Filters
.services-filters-date {
  .rs-picker-toggle {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  display: inline-block;
  width: 200px;
  margin: 0 10px;
}
