.drop_container {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}
.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 25px;
  border-width: 2px;
  border-radius: 5px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}
.dropzone:hover {
  border-color: #c0baba;
  color: #c0baba;
}
.drop_button {
  position: relative;
  top: -110px;
  right: -88px;
}
