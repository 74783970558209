.shipment_comp {
  margin: 10px 20px 30px 40px;
  // width:90%;
}

.smallinfo {
  font-size: 0.85rem;
  color: gray;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.space {
  justify-content: space-between;
}

.outbound-filters {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  margin-bottom: 0.4em;
  .po-filters__filter {
    margin-right: 0.6em;
    margin-bottom: 0.8em;
    display: inline-block;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    .po-filters__filter {
      width: 100%;
    }
  }
}
