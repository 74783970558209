.product-group-container {
  margin-top: 1.5em;
  margin-bottom: 2em;
}

.product-group-columns {
  display: flex;
  justify-content: space-between;
  padding: 2em 1.3em 2em;
  @media (max-width: 900px) {
    flex-direction: column;
  }
}
.product-group-columns__info {
  width: 60%;
  position: relative;
  @media (max-width: 900px) {
    width: 100%;
  }
}
.product-group-columns__add-prod {
  width: 35%;
  position: relative;
  @media (max-width: 900px) {
    width: 100%;
    margin-top: 3em;
  }
}
.pg-add-products-list-wrapper {
  max-height: 300px;
  overflow-y: scroll;
}

// CREATE
// .product-group-columns_create {
//   justify-content: flex-start;
// }
// .product-group-columns__form {
//   width: 35%;
//   margin-right: 3em;
//   position: relative;
//   @media (max-width: 900px) {
//     width: 100%;
//     margin-right: 0;
//   }
// }

// LIST
.pg-ellipsis-btn {
  border: none;
  outline: none !important;
  background: transparent;
  cursor: pointer;
  margin-left: 1.3em;
  margin-top: 0;
  color: #007ad9;
}
.pg-ellipsis-btn:hover {
  color: #0057d9;
}
