/* .navbar-brand>img {
    display: inline-block;
    margin-bottom: 2px;
    width: 15px;
    border-radius: 2px;
}

.nav>li>a>img {
    margin: -20px 0 -15px;
    width: 30px;
    border-radius: 3px;
} */

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .nav_right {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .lables {
    display: flex;
    justify-self: start;
    margin-right: 30px;
    @media (max-width: 700px) {
      margin-right: 0px;
    }
  }
  @media (max-width: 700px) {
    display: flex;
    align-items: center;
  }
  ul {
    padding: 0;
  }
}

.nav_user {
  justify-self: end;
  margin-right: 25px;
  margin: 0.3em 1.5em 0.5em;

  ul {
    list-style-type: none;
    margin-bottom: 0;
    text-align: right;
  }
}
.nav_logo {
  margin-left: 4px;
}

.menu__link {
  padding: 0rem 0.6rem;
  @media (max-width: 735px) {
    padding: 1rem 2rem;
  }
}
.admin__header {
  grid-area: navbar;
}
.nav_left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.ui.menu .item {
  text-align: left !important;
}

// Submenu
.navigation-submenu__header {
  display: flex;
  justify-content: space-between;
  padding-left: 8.4px;
}
.navigation-submenu__body {
  background-color: rgb(61, 61, 61);
  font-size: 12px;
  font-weight: normal;
}
