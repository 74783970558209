// Static Variables

$admin-header-height: 50px;
$admin-footer-height: 30px;

// Global CSS

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  background: #f2f6f7 !important;
  

}



// Main Layout Grid
// #root {
//   height: 100%;
// }
.admin {
  --spacing: 1rem;

  // // Flexbox Fallback
  // display: flex;
  // flex-wrap: wrap;

  // // Grid
  // display: grid;
  // height: 100vh;

  // grid-template-rows: auto 1fr;

  // grid-template-areas: var(--grid-areas);

  &__header {
    color: white;
    background-color: black;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    position: fixed;
    width: 100%;
    top: 0;
    overflow: hidden;
    z-index: 9;
  }

  &__footer {
    display: flex;
    grid-area: footer;
    flex-basis: 100%;
    justify-content: space-between;
    align-items: center;
    height: $admin-footer-height;
    padding: 0 var(--spacing);
    color: #4e5561;
    background-color: #1d2127;
  }

  @media screen and (min-width: 48rem) {
    --spacing: 2rem;
  }
}

// Dashboard Overview Grid

.dashboard {
  --column-count: 2;

  // Flexbox Fallback
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--spacing) * -0.5);

  // Grid
  display: grid;
  grid-template-columns: repeat(var(--column-count), 1fr);
  grid-gap: var(--spacing);

  &__item {
    // By default, items span 2 columns
    flex: 1 1 50%;
    grid-column-end: span 2;
    padding: calc(var(--spacing) / 2);

    // these span the full width
    &--full {
      flex-basis: 100%;
      grid-column: 1 / -1;
    }

    // these span only one column
    &--col {
      flex-basis: calc(100% / var(--column-count));
      grid-column-end: span 1;
    }
  }

  // Switch to 4-col grid on larger screens

  @media screen and (min-width: 48rem) {
    --column-count: 4;
  }

  @supports (display: grid) {
    margin: 0;

    &__item {
      padding: 0;
    }
  }
}

// ===============================
// Demo stuff to make it look nice
// ===============================

a {
  color: #dc5a60;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

// .logo {
//   display: flex;
//   flex: 0 0 $admin-nav-width;
//   height: $admin-header-height;
//   justify-content: center;
//   align-items: center;
//   position: relative;
//   margin: 0;
//   color: #fff;
//   background-color: #dc5a60;
//   font-size: 1rem;

//   h1 {
//     margin: 0;
//   }
// }

.toolbar {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--spacing);
}

.menu {
  list-style-type: none;
  padding: 0;

  &__item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  }

  &__link {
    display: block;
    padding: 1rem 2rem;
    color: #76808f;
    text-decoration: none;

    &:hover,
    &:focus {
      color: #fff;
      background-color: #1f222d;
    }
  }
}

.card {
  height: 100%;
  padding: 1rem;
  font-size: 2rem;
  font-weight: 300;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.btn {
  display: inline-block;
  border-radius: 5em;
  border: 0;
  padding: 0.5rem 1rem;
  white-space: nowrap;

  &--primary {
    color: #fff;
    background-color: #56bf89;
  }
}

.ticker {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  &__item {
    margin-right: 1rem;
  }
}
.createOrder {
  margin: 10px 20px 30px 40px;
}

.flex_spaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex_alignRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.flex_alignLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.flex_column_spaceBetween {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main_app {
  // height: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 50px auto;
  grid-template-areas:
    "navbar"
    "body";
  // grid-row-gap: 47px;
  .wrapper {
    grid-area: body;
    min-height: 100vh;
    overflow-y: hidden;
    // overflow-y: scroll;
    -webkit-scrollbar {
      width: 10px;
    }
    nav {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .pushable {
      margin-top: 0;
      position: relative;
      top: 0;
      min-height: 100vh !important;
    }
    .pusher {
      min-height: 100vh !important;
    }
    .visible-pusher {
      width: calc(100vw - 150px);
    }

    .app-sidebar {
      height: 100%;
      min-height: 100vh !important;
      // position: relative !important;
      // left: 0;
    }

    .app-sidebar__nav {
      position: fixed;
      left: 0;
      margin-right: 0;
      width: 100%;
    }
  }

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
    margin-top: 47px;
    -webkit-scrollbar {
      width: 0 !important;
    }
  }
}

// .blur >div:not(.po_client_form){
// filter: blur(1.3px)
// }
