.goodreceived {
  display: flex;
}
a {
  cursor: pointer;
}

.edit_but {
  padding-left: 0;
}
#parties {
  margin-top: 0;
}

#addbutton {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 15%;
  height: 19%;
  margin: 0px 0px 0px 20px;
}

.pocomponent {
  display: grid;
  grid-template-areas: "po comments";
  grid-template-columns: 70% auto;
  grid-gap: 5px;

  margin-bottom: 20px;
}

.po_client_form {
  /* width:60%; */
  display: flex;
  align-items: center;
  justify-content: center;
  filter: blur(0);
}

.po-filters__first-row {
  margin-bottom: 0.6em;
}
// .po-filters__dates {
//   margin-bottom: 0.4em;
// }
.po-filters__filter {
  margin-right: 0.6em;
  margin-bottom: 0.4em;
  display: inline-block;
}
