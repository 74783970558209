.po_ {
  &comment_body {
    // padding:5px;
    // background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: flex-start;
    // margin-left: 10px;
    // width:350px;
    max-height: 450px;
    // -webkit-box-shadow: -3px 1px 57px -21px rgba(0, 0, 0, 0.75);
    // -moz-box-shadow: -3px 1px 57px -21px rgba(0, 0, 0, 0.75);
    // box-shadow: -3px 1px 57px -21px rgba(0, 0, 0, 0.75);

    .comment__header {
      padding: 0;
      height: 20px;
      text-align: justify;
      p {
        margin: 8px;
        font-weight: bold;
        text-decoration: underline;
      }
    }

    .comment__list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 8px 0 0 3px;
      text-align: right;
      max-height: 70%;
      overflow: auto;
      list-style-type: none;

      .comment {
        display: grid;
        grid-template-columns: 30% 70%;
        column-gap: 10px;
        justify-content: left;
        text-align: left;
        padding-top: 0.5em;
        margin: 0px 0px 15px 0px;
        border-top: 1px solid rgba(128, 128, 128, 0.308);

        // .comment__meta {
        //   width: 30%;
        // }
        .comment__date {
          font-size: 0.7em;
        }
        .comment__text {
          margin-left: 20px;
          text-align: left;
          font-size: 0.9em;
          max-width: 60%;
          overflow-wrap: break-word;
          // word-wrap: break-word;
          // li {
          //   // overflow-wrap: break-word;
          //   // word-wrap: break-word;
          // }
        }
      }
    }
    .comment__form {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 16px 0 0;
      .comment_text-area {
        margin-right: 10px;
        flex: auto;
      }
      a {
        font-size: 0.8em;
      }
    }

    /* .comment_body{
      padding-left:10px;
  } */
  }
}
