.user-form__form-section {
  max-width: 600px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, auto);
  column-gap: 2em;
  margin-bottom: 2em;

  @media (max-width: 530px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, auto);
    column-gap: 0;
  }
}
.user-form__form-section_details {
  grid-template-rows: repeat(2, auto);

  @media (max-width: 530px) {
    // grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
  }
}

.user-form__filed {
  max-width: 220px;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.7em;
}

.user-form__error-msg {
  color: rgb(97, 30, 30);
  font-size: 13px;
}
// .user-form__filed_password {
//   grid-column-start: 1;
//   grid-column-end: 3;
//   grid-row-start: 2;
// }

/// List
.user-filters {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1em;

  @media (max-width: 1080px) and (min-width: 1040px) {
    column-gap: 0.6em;
  }

  @media (max-width: 1040px) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 0.6em;
  }

  @media (max-width: 620px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
