.blue-link {
  color: rgb(27, 91, 150) !important;
}
.blue-link:hover {
  color: rgb(17, 142, 146) !important;
}

.shadow {
  -webkit-box-shadow: 0px -1px 10px -1px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0px -1px 10px -1px rgba(0, 0, 0, 0.19);
  box-shadow: 0px -1px 10px -1px rgba(0, 0, 0, 0.19);
  padding: 10px;
  width: 60%;
}

// HEAD COMPONENT
.head-component {
  margin-top: 1em;
  margin-bottom: 1em;
}
.head {
  display: block;
}

// COMPANY-FORM COMPONENT
.company-form {
  padding: 1em 1em;
  width: 400px !important;
  display: block;
}
.company-form__row {
  margin-bottom: 0.9em;
}
.company-form__row_big-mb {
  margin-bottom: 1.5em;
}
.company-form__input-row {
  margin-bottom: 0.7em !important;
}

// EDITABLE DROPDOWN
.editable-field__text.editable {
  height: 38px;
  line-height: 37px;
  background-color: #f7f7f7;
  border-bottom: #f7f7f7 1px solid;
  border-top: #f7f7f7 1px solid;
  &:hover {
    border-bottom: #d3d3d3 1px solid;
    background-color: #f5f5f5;
    border-top: #f5f5f5 1px solid;
  }
}

// EDITABLE TEXT
.editable-field__text.editable_text {
  // width: 5em;
  background-color: #ffffff;
  border-bottom: #f7f7f7 1px solid;
  border-top: #ffffff 1px solid;
  padding-left: 3px;
}

// NOTIFICATION
.notification {
  position: fixed !important;
  z-index: 100;
  // top: 0.7em;
  // right: 2em;
  // left: 2em;
  bottom: 1.5em;
  right: 2em;
  border-radius: 0.4em;
  padding: 1em 4em 1em 2em;
  color: white;
  max-width: max-content;
  transform-origin: top left;
}
.notification_error {
  background-color: #ff3a3a;
}
.notification_warning {
  background-color: #fde246;
}
.notification_success {
  background-color: #5fc746;
}
.notification_info {
  background-color: #73a6f3;
}

.notification--button {
  border-radius: 50%;
  border: none;
  position: absolute;
  top: 8px;
  right: 8px;
  transition: background-color 0.3s;
  outline: none !important;
  background-color: transparent;
  color: white;
  line-height: 1.5;
}
.notification--button:hover {
  background-color: rgba(255, 255, 255, 0.219);
}

.fade-in {
  animation: fadeIn 0.3s cubic-bezier(0, 0.77, 0.51, 0.37);
  // animation: fadeIn 0.4s cubic-bezier(0.54, 1.29, 0.94, 0.96);
}
// @keyframes fadeIn {
//   from {
//     opacity: 0.6;
//     transform: translateY(-50px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// }

@keyframes fadeIn {
  0% {
    opacity: 0.6;
    transform: translateY(-50px) scaleX(0.1);
  }
  20% {
    opacity: 1;
    transform: translateY(0) scaleX(0.1);
  }
  30% {
    opacity: 1;
    transform: translateY(0) scaleX(0.1);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scaleX(1);
  }
}

.notification--text-on {
  opacity: 0;
  animation: textOn 0.2s ease-in 0.3s forwards;
}
@keyframes textOn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Not Found Page
.not-found {
  min-height: calc(100vh - 4.5em);
  display: flex;
  align-items: center;
  justify-content: center;
}
.not-found--wrapper {
  text-align: center;
}
h1.ui.not-found--h1 {
  font-size: 5em;
}
h2.ui.not-found--h2 {
  font-size: 2.5em;
}
