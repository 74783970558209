.businessclient {
  margin-top: 2em;
  display: grid;
  grid-template-columns: 1fr 3.5fr;
  row-gap: 2em;
  column-gap: 3em;

  .businessclient__form {
    padding-right: 2em;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    row-gap: 2.5em;
    .businessclient__form {
      padding-right: 0;
    }
  }
  @media (max-width: 1000px) and (min-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 2.5em;
    .businessclient__form {
      padding-right: 0;
    }
  }
}

.businessclient__sidebar {
  background-color: rgba(229, 238, 243, 0.37);
  padding: 2em;
  padding-bottom: 1em;
  text-align: right;

  @media (max-width: 500px) {
    text-align: left;
  }
  @media (max-width: 1000px) and (min-width: 700px) {
    text-align: left;
  }
}

// View Page
.businessclient {
  .list-item {
    display: grid;
    grid-template-columns: 3em auto;
    margin-bottom: 2.5em;

    .list-item__delete-btn {
      display: none;
    }
    &:hover .list-item__delete-btn {
      display: block;
    }
  }
  .list-item__label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

div.view-grid_no-bg {
  background-color: #fff;
}
.view-grid {
  padding: 0.5em 0.8em 0.3em;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 4em;
  background-color: rgba(229, 238, 243, 0.37);

  @media (max-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.view-grid__item {
  display: flex;
}
.view-grid__label {
  min-width: 85px;
}

// Profile Settings
.profile-settings {
  margin-top: 3em;
  padding: 1em;
  margin-bottom: 10em;
}

.reveal {
  margin-bottom: 1em;
  background-color: rgb(255, 255, 255);
  box-shadow: 1px 5px 10px 10px rgba(51, 51, 51, 0.027),
    1px 1px 2px 2px rgba(51, 51, 51, 0.089);
}

.reveal__header {
  padding: 1em 1.2em;
  background-color: rgb(250, 250, 250);
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: rgb(243, 243, 243);
    cursor: pointer;
  }
}
// .reveal__header-toggle {
//   cursor: pointer;
// }

.reveal__body {
  .settings-list {
    padding: 1em;
    // padding-left: 5em;

    .setting-list__editable-text {
      width: 100%;
      max-width: 200px;
      margin-left: 1em;
      margin-bottom: 0.3em;
    }

    li {
      list-style-type: none;
      display: flex;
      align-items: center;

      .checkbox {
        margin-right: 3em;
      }
    }
  }
}

// Quotations Modal
.quotations-modal-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2em;
  row-gap: 0.5em;
  margin-bottom: 2em;
  padding: 0.5em;
}
.quotations-modal-label-input {
  display: flex;
  max-width: 200px;
  flex-direction: column;
  margin-bottom: 0.4em;
}

.quotations-prices-list {
  padding-left: 1.5em;
  width: 180px;
  list-style-type: circle;
}
