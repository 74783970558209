.bill-grid {
  display: flex;
  justify-content: space-between;
}

.bill-grid__details {
  width: 30%;
  @media screen and(max-width: 600px) {
    width: 100%;
  }
}
.bill-description {
  margin-bottom: 7px;
  .bill-description__label {
    font-weight: bold;
    min-width: 100px;
    display: inline-block;
  }
  .bill-description__text {
    font-size: 15px;
  }
}

.bill-subdescription {
  font-size: 11px;
  padding-left: 10px;
  .bill-description {
    margin-bottom: 0px;
  }
  .bill-description__label {
    margin-bottom: 2px;
  }
  .bill-description__text {
    font-size: 14px;
  }
}

.bill-grid__items {
  width: 65%;
}

// CREATE BILL
.create-bill__section {
  margin-bottom: 1em;
}
.create-bill__label {
  display: inline-block;
  min-width: 160px;
  margin-left: 1em;
}
.create-bill__number {
  display: inline-block;
}

.client-info {
  margin: 1em 4em 3em 4em;
  border: 1px solid rgb(191, 224, 247);
  border-radius: 8px;
  background-color: rgb(233, 246, 255);
  padding: 1em;
  display: flex;
}
.client-info__seaction {
  width: 50%;
  font-size: 0.9em;
}

.bill-add-item {
  border: 1px solid rgb(247, 247, 247);
  background-color: rgb(247, 247, 247);
  border-radius: 8px;
  margin: 41px 1em 1em 1em;

  // padding: 0.5em 1em;
  max-width: max-content;
  // margin: 0 auto 2.5em 4em;
}
.bill-add-item__field {
  margin-bottom: 0.6em;
}
.bill-add-item__label {
  display: inline-block;
  min-width: 140px;
  margin-right: 0.2em;
  font-weight: normal;
}
.create-bill-items-table {
  margin-left: 4em;
  margin-bottom: 3em;
  margin-top: 3.5em;
}
.create-bill-add-item-btn {
  margin-left: 4em;
  margin-top: 1em;
}

/// BILL SERVICES SUMMARY
.bill-summary-grid {
  display: grid;
  grid-template-columns: minmax(max-content, 140px) max-content;
  column-gap: 2em;
}

// creditnote
.creditnote-grid {
  display: grid;
  grid-template-columns: auto auto;
  @media (max-width: 800px) {
    grid-template-columns: auto;
  }
}
